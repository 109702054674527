import React, { useEffect, useState, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import SelectModal from '../custom/select/SelectModal';
import { Order, Algorithm } from '../../services';
import Button from "react-bootstrap/Button";
import { numberToFinanceFormat } from '../../utils';

const CalculatorPage = () => {

    const [showCountryFromSelect, setShowCountryFromSelect] = useState(false);
    const [showCountryToSelect, setShowCountryToSelect] = useState(false);
    const [showCityFromSelect, setShowCityFromSelect] = useState(false);
    const [showCityToSelect, setShowCityToSelect] = useState(false);
    const [showCategorySelect, setShowCategorySelect] = useState(false);
    const [showTypeSelect, setShowTypeSelect] = useState(false);

    const [countries, setCountries] = useState();
    const [citiesFrom, setCitiesFrom] = useState();
    const [citiesTo, setCitiesTo] = useState();
    const [categories, setCategories] = useState();
    const [types, setTypes] = useState();

    const [countryFrom, setCountryFrom] = useState();
    const [cityFrom, setCityFrom] = useState();
    const [countryTo, setCountryTo] = useState();
    const [cityTo, setCityTo] = useState();
    const [cargoCategory, setCargoCategory] = useState();
    const [vehicleType, setVehicleType] = useState();
    const [weight, setWeight] = useState();
    const [volume, setVolume] = useState();
    const [globalFactor, setGlobalFactor] = useState();
    const [driversShare, setDriversShare] = useState();

    const [errorTitle, setErrorTitle] = useState();

    const [calcLoading, setCalcLoading] = useState(false);
    const [calcResult, setCalcResult] = useState();

    const bottomRef = useRef(null);

    useEffect(() => {
        Order.getCountries()
        .then(response => {
            console.log('countries', response.data)
            setCountries(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        Order.getCategories()
        .then(response => {
            console.log('categories ---->', response.data)
            setCategories(response.data);
        })
        .catch(error => {
            console.log(error);
        });

        Order.getTypes()
        .then(response => {
            console.log('types', response.data)
            setTypes(response.data);
        })
        .catch(error => {
            console.log(error);
        });
        
    }, []);

    useEffect(() => {
        if(countryFrom) {
            Order.getCities(countryFrom.id)
            .then(response => {
                console.log('cities from', response)
                setCitiesFrom(response.data);
            })
            .catch(error => {
                console.log(error);
            });
        }
    }, [countryFrom]);

    useEffect(() => {
        if(countryTo) {
            Order.getCities(countryTo.id)
            .then(response => {
                console.log('cities from', response)
                setCitiesTo(response.data);
            })
            .catch(error => {
                console.log(error);
            });
        }
    }, [countryTo]);

    useEffect(() => {
        if(calcResult) {
            bottomRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    })

    const onSumbit = () => {
        if(countryFrom && cityFrom && countryTo && cityTo && cargoCategory && vehicleType && weight && volume && globalFactor && driversShare) {
            setErrorTitle();

            setCalcLoading(true);
            setCalcResult();

            const data = {
                "cityIdFrom": cityFrom.id,
                "cityIdTo": cityTo.id,
                "transportType": vehicleType.value,
                "cargoCategory": cargoCategory.value,
                "weight": weight,
                "volume": volume,
                "globalFactor": globalFactor,
                "driversShare": driversShare
              }

              console.log(data)

              Algorithm.calculate(data)
              .then(response => {
                  console.log('calc response', response);
                  if(response.status == 200){
                    setCalcLoading(false);
                    setCalcResult(response.data);
                  }else {
                    setCalcLoading(false);
                    alert("Произошла ошибка, попробуйте позже")
                  }
              })
              .catch(error => {
                  console.log(error);
                  setCalcLoading(false);
                  alert("Произошла ошибка, попробуйте позже")
              });

        }else {
            setErrorTitle('Необходимо заполнить все поля');
        }
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'left', fontWeight: '700', fontSize: '24px' }}>Калькулятор стоимости</div>
            </div>
            <div style={{ marginTop: '20px' }}>
                <Card>
                    <Card.Body>
                        <div className='col-9'>
                            <div className="mb-2">
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Страна отправки груза</Form.Label>
                                <div onClick={() => setShowCountryFromSelect(true)} style={{ color: `${countryFrom ? '#212529' : '#A3ACB6'}`, fontSize: '14px', backgroundColor: '#ffffff', padding: '0.375rem 0.75rem', border: '1px solid #ced4da', borderRadius: '0.375rem' }}>
                                    {countryFrom ? countryFrom.name : 'Выберите страну'}
                                </div>
                            </div> 
                        </div>
                        <div className='col-9'>
                            <div className="mb-2">
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Город отправки груза</Form.Label>
                                <div onClick={() => countryFrom ? setShowCityFromSelect(true) : null} style={{ color: `${cityFrom ? '#212529' : '#A3ACB6'}`, fontSize: '14px', backgroundColor: `${ countryFrom ? '#ffffff' : '#e9ecef' }`, padding: '0.375rem 0.75rem', border: '1px solid #ced4da', borderRadius: '0.375rem' }}>
                                    {cityFrom ? cityFrom.name : 'Выберите город'}
                                </div>
                            </div> 
                        </div>


                        <div className='col-9'>
                            <div className="mb-2">
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Страна доставки груза</Form.Label>
                                <div onClick={() => setShowCountryToSelect(true)} style={{ color: `${countryTo ? '#212529' : '#A3ACB6'}`, fontSize: '14px', backgroundColor: '#ffffff', padding: '0.375rem 0.75rem', border: '1px solid #ced4da', borderRadius: '0.375rem' }}>
                                    {countryTo ? countryTo.name : 'Выберите страну'}
                                </div>
                            </div> 
                        </div>
                        <div className='col-9'>
                            <div className="mb-2">
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Город доставки груза</Form.Label>
                                <div onClick={() => countryTo ? setShowCityToSelect(true) : null} style={{ color: `${cityTo ? '#212529' : '#A3ACB6'}`, fontSize: '14px', backgroundColor: `${ countryTo ? '#ffffff' : '#e9ecef' }`, padding: '0.375rem 0.75rem', border: '1px solid #ced4da', borderRadius: '0.375rem' }}>
                                    {cityTo ? cityTo.name : 'Выберите город'}
                                </div>
                            </div> 
                        </div>
                        <div className='col-9'>
                            <div className="mb-2">
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Категория груза</Form.Label>
                                <div onClick={() => setShowCategorySelect(true)} style={{ color: `${cargoCategory ? '#212529' : '#A3ACB6'}`, fontSize: '14px', backgroundColor: '#ffffff', padding: '0.375rem 0.75rem', border: '1px solid #ced4da', borderRadius: '0.375rem' }}>
                                    {cargoCategory ? cargoCategory.name : 'Выберите категорию'}
                                </div>
                            </div> 
                        </div>
                        <div className='col-9'>
                            <div className="mb-2">
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Тип транспорта</Form.Label>
                                <div onClick={() => setShowTypeSelect(true)} style={{ color: `${vehicleType ? '#212529' : '#A3ACB6'}`, fontSize: '14px', backgroundColor: '#ffffff', padding: '0.375rem 0.75rem', border: '1px solid #ced4da', borderRadius: '0.375rem' }}>
                                    {vehicleType ? vehicleType.name : 'Выберите тип'}
                                </div>
                            </div> 
                        </div>

                        <div className='col-9'>
                            <Form.Group className="mb-2" >
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Вес</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control type="number" placeholder="Введите вес" value={weight} onChange={(event) => setWeight(event.target.value)} style={{ fontSize: '14px' }} />
                                    <div style={{marginLeft: '12px', fontSize: '15px'}}>тн.</div>
                                </div>
                            </Form.Group>
                        </div>

                        <div className='col-9'>
                            <Form.Group className="mb-2" >
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Объем</Form.Label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Control type="number" placeholder="Введите объем" value={volume} onChange={(event) => setVolume(event.target.value)} style={{ fontSize: '14px' }} />
                                    <div style={{marginLeft: '12px', fontSize: '15px'}}>м<sup>3</sup></div>
                                </div>
                            </Form.Group>
                        </div>

                        <div className='col-9'>
                            <Form.Group className="mb-2" >
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Global Factor</Form.Label>
                                <Form.Control type="number" placeholder="Введите global factor" value={globalFactor} onChange={(event) => setGlobalFactor(event.target.value)} style={{ fontSize: '14px' }} />
                            </Form.Group>
                        </div>

                        <div className='col-9'>
                            <Form.Group className="mb-2" >
                                <Form.Label style={{ color: '#A3ACB6', fontSize: '12px' }}>Drivers Share</Form.Label>
                                <Form.Control type="number" placeholder="Введите drivers share" value={driversShare} onChange={(event) => setDriversShare(event.target.value)} style={{ fontSize: '14px' }} />
                            </Form.Group>
                        </div>

                        {errorTitle ? <div style={{ color: 'red', marginTop: '16px', textAlign: 'left', fontSize: '14px' }}>{errorTitle}</div> : null}

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
                            <div>
                                <Button variant="secondary">Очистить форму</Button>
                            </div>
                            <div>
                            <Button variant="success" onClick={onSumbit} disabled={calcLoading ? true : false}>{calcLoading ? 'Подождите, идет подсчет стоимости..' : 'Посчитать стоимость'}</Button>
                            </div>
                        </div>

                    </Card.Body>
                </Card>

                {
                    calcResult ?
                    <Card ref={bottomRef} style={{ marginTop: '24px' }}>
                        <Card.Body>
                            <div style={{ textAlign: 'left', fontWeight: '700', fontSize: '20px' }}>Результат</div>
                            <div className='row' style={{ marginTop: '16px' }}>
                                <div className='col-3'>
                                    <div style={{ color: '#A3ACB6', fontSize: '14px' }}>Distance</div>
                                    <div style={{ fontWeight: '700', fontSize: '19px' }}>{numberToFinanceFormat(calcResult.distance)} км</div>
                                </div>
                                <div className='col-3'>
                                    <div style={{ color: '#A3ACB6', fontSize: '14px' }}>Price</div>
                                    <div style={{ fontWeight: '700', fontSize: '19px' }}>{numberToFinanceFormat(calcResult.price)} тг</div>
                                </div>
                                <div className='col-3'>
                                    <div style={{ color: '#A3ACB6', fontSize: '14px' }}>Net Price</div>
                                    <div style={{ fontWeight: '700', fontSize: '19px' }}>{numberToFinanceFormat(calcResult.netPrice)} тг</div>
                                </div>
                                <div className='col-3'>
                                    <div style={{ color: '#A3ACB6', fontSize: '14px' }}>Discount</div>
                                    <div style={{ fontWeight: '700', fontSize: '19px' }}>{calcResult.discount}</div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card> : null
                }
            </div>

            { 
                showCountryFromSelect && 
                <SelectModal 
                    onClose={() => setShowCountryFromSelect(false)} 
                    header="Укажите страну"
                    options={countries}
                    onSubmit={(item) => {setCountryFrom(item); setShowCountryFromSelect(false)}}
                /> 
            }
            {
                showCityFromSelect &&
                <SelectModal 
                    onClose={() => setShowCityFromSelect(false)} 
                    header="Укажите город"
                    options={citiesFrom}
                    onSubmit={(item) => {setCityFrom(item); setShowCityFromSelect(false)}}
                    search={true}
                    searchTitle="Поиск по названию города"
                /> 
            }
            { 
                showCountryToSelect && 
                <SelectModal 
                    onClose={() => setShowCountryToSelect(false)} 
                    header="Укажите страну"
                    options={countries}
                    onSubmit={(item) => {setCountryTo(item); setShowCountryToSelect(false)}}
                /> 
            }
            {
                showCityToSelect &&
                <SelectModal 
                    onClose={() => setShowCityToSelect(false)} 
                    header="Укажите город"
                    options={citiesTo}
                    onSubmit={(item) => {setCityTo(item); setShowCityToSelect(false)}}
                    search={true}
                    searchTitle="Поиск по названию города"
                /> 
            }
            {
                showCategorySelect &&
                <SelectModal 
                    onClose={() => setShowCategorySelect(false)} 
                    header="Укажите категорию груза"
                    options={categories}
                    onSubmit={(item) => {setCargoCategory(item); setShowCategorySelect(false)}}
                /> 
            }
            {
                showTypeSelect &&
                <SelectModal 
                    onClose={() => setShowTypeSelect(false)} 
                    header="Укажите тип транспорта"
                    options={types}
                    onSubmit={(item) => {setVehicleType(item); setShowTypeSelect(false)}}
                /> 
            }
        </>
    );
}

export default CalculatorPage;