import React, {useEffect} from 'react';
import './App.css';
import MenuComponent from './components/menu/MenuComponent';
import {BrowserRouter as Router, Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import OrdersPage from './components/orders/OrdersPage';
import DriversPage from './components/drivers/DriversPage';
import ClientsPage from './components/clients/ClientsPage';
import LoginPage from './components/login/LoginPage';
import {AuthContext} from './context/auth.context';
import CategoriesPage from './components/categories/CategoriesPage';
import TypesPage from './components/types/TypesPage';
import CountriesPage from './components/countries/CountriesPage';
import CitiesPage from './components/cities/CitiesPage';
import PaymentsPage from './components/payments/PaymentsPage';
import DistancePage from './components/distance/DIstancePage';
import UsersPage from './components/users/UsersPage';
import RolesPage from './components/roles/RolesPage';
import {SystemRoles, SystemUsers} from './services'
import {setUserData} from './store/actions/userAction';
import {setRoleData} from './store/actions/roleAction';
import {useDispatch, useSelector} from 'react-redux';
import CalculatorPage from './components/calculator/CalculatorPage';
import ContactsPage from "./components/contacts/ContactsPage";
import OrderCancelMessagesPage from "./components/orderCancelMessages/OrderCancelMessagesPage";
import MetricsPage from "./components/metrics/MetricsPage";
import {SignalRProvider} from "./context/signalR.context";
import moment from "moment/moment";
import axios from "axios";
import {configData} from "./services/api-config";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import EmailSenderPage from "./components/email-sender/EmailSenderPage";
import { Value } from 'sass';
import NewsPage from "./components/news/NewsPage";
import FeedbackRequestPage from "./components/feedback/FeedbackRequestPage";
import InsurancePage from "./components/insurance/InsurancePage";
import ReportPage from "./components/reports/ReportPage";
import ClientContractPage from "./components/client-contract/ClientContractPage";
import NotificationBell from './components/notification/notificationBell/NotificationBell';
import NotificationPage from './components/notification/NotificationPage';
import OrderTabsPage from "./components/orders-tabs/OrderTabsPage";

function App({login, logout, isAuthenticated, checkDone}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    useEffect(() => {
        if (isAuthenticated) {
            SystemUsers.getUser()
                .then(response => {
                    dispatch(setUserData(response.data));
                    localStorage.setItem('userData', JSON.stringify(response.data));
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (user && user.roleId) {
            SystemRoles.getById(user.roleId)
                .then(response => {
                    dispatch(setRoleData(response.data));
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [user]);

    useEffect(() => {
        if (isAuthenticated) {
            const interval = setInterval(() => {

                const dt = new Date();
                let diffTZ = dt.getTimezoneOffset();
                const hour = dt.getHours();
                const day = dt.getDate();

                const storage = JSON.parse(localStorage.getItem('authData'))
                if (storage && storage.date) {
                    const tokenDate = new Date(storage.date);
                    if (tokenDate.getDate() !== day) {
                        axios.post(configData.BASE_URL + 'api/v1/users/logout');
                        localStorage.removeItem('authData');
                        sessionStorage.clear();
                        window.location.href = '/login';
                    }
                }

                // if (diffTZ == -360 && [0, 1, 2, 3, 4, 5].includes(hour)) {
                //     axios.post(configData.BASE_URL + 'api/v1/users/logout');
                //     localStorage.removeItem('authData');
                //     sessionStorage.clear();
                // }

            }, 5000);

            return () => clearInterval(interval);
        }
    }, [isAuthenticated]);

    return (
        <AuthContext.Provider
            value={{
                login,
                logout,
                isAuthenticated,
                checkDone
            }}
        >
            <SignalRProvider>
                <Router>
                    {
                        isAuthenticated
                            ?
                            <div style={{width: '100vw', backgroundColor: '#f4f6f9', position: 'relative'}}>
                                <MenuComponent/>
                                <NotificationBell />
                                <div style={{marginLeft: '65px', height: '100vh', overflowY: 'auto', padding: '24px'}}>
                                    <Routes>
                                        <Route exact path="/orders" element={<OrdersPage/>}></Route>
                                        <Route exact path="/drivers"
                                               element={<DriversPage status="" title="Водители"/>}></Route>
                                        <Route exact path="/insurance"
                                               element={<InsurancePage status="" title="Страховка"/>}></Route>
                                        <Route exact path="/clients"
                                               element={<ClientsPage status="" title="Клиенты"/>}></Route>
                                        <Route exact path="/payments" element={<PaymentsPage/>}></Route>
                                        <Route exact path="/client-requests" element={<ClientsPage status="Moderation"
                                                                                                   title="Модерация клиентов"/>}></Route>
                                        <Route exact path='/driver-requests' element={<DriversPage status="Moderation"
                                                                                                   title="Модерация водителей"/>}></Route>
                                        <Route exact path="/categories" element={<CategoriesPage/>}></Route>
                                        <Route exact path="/types" element={<TypesPage/>}></Route>
                                        <Route exact path="/countries" element={<CountriesPage/>}></Route>
                                        <Route exact path="/cities" element={<CitiesPage/>}></Route>
                                        <Route exact path="/distance" element={<DistancePage/>}></Route>
                                        <Route exact path="/users" element={<UsersPage/>}></Route>
                                        <Route exact path="/roles" element={<RolesPage/>}></Route>
                                        <Route exact path="/calculator" element={<CalculatorPage/>}></Route>
                                        <Route exact path="/contacts" element={<ContactsPage/>}></Route>
                                        <Route exact path="/order-cancel-messages"
                                               element={<OrderCancelMessagesPage/>}></Route>
                                        <Route exact path="/metrics" element={<MetricsPage/>}></Route>
                                        <Route exact path="/news" element={<NewsPage/>}></Route>
                                        <Route exact path="/client-contract" element={<ClientContractPage/>}></Route>
                                        <Route exact path="/feedback-request" element={<FeedbackRequestPage/>}></Route>
                                        <Route exact path="/email-sender" element={<EmailSenderPage/>}></Route>
                                        <Route exact path="/reports" element={<ReportPage/>}></Route>
                                        <Route exact path="/notification" element={<NotificationPage/>}></Route>
                                        <Route exact path="/order-tabs" element={<OrderTabsPage/>}></Route>
                                        <Route path="*" element={<Navigate to="/orders" replace/>}/>
                                    </Routes>
                                </div>
                            </div>
                            :
                            <Routes>
                                <Route exact path="/login" element={<LoginPage/>}></Route>
                                {/*<Route exact path="*" element={<LoginPage />}></Route>*/}
                                <Route path="/" element={<Navigate to="/login" replace/>}/>
                            </Routes>
                    }

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </Router>

            </SignalRProvider>
        </AuthContext.Provider>
    );
}

export default App;
