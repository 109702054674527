export const PERMISSIONS = [
    'orders_view',
    'orders_moderation',
    'clients_view',
    'client_moderation',
    'drivers_view',
    'insurance',
    'driver_moderation',
    'client_payments_view',
    'client_payment_moderation',
    'drivers_payments_view',
    'drivers_payments_confirm',
    'driver_payment_moderation',
    'cargo_categories_view',
    'transport_types_view',
    'countries_view',
    'cities_view',
    'system_users_view',
    'system_roles_view',
    'calculator_view',
    'contacts_view',
    'order_cancel_messages_view',
    'metrics_view',
    'news',
    'client-contract',
    'feedback_request',
    'email_sender',
    "reports"
];

export const PERMISSIONS_DATA = {
    'orders_view': 'Просмотр заказов',
    'orders_moderation': 'Модерация заказов',
    'clients_view': 'Просмотр клиентов',
    'client_moderation': 'Модерация клиентов',
    'drivers_view': 'Просмотр водителей',
    'insurance': 'Страховка',
    'driver_moderation': 'Модерация водителей',
    'client_payments_view': 'Просмотр оплат клиентов',
    'client_payment_moderation': 'Модерация оплат клиентов',
    'drivers_payments_view': 'Просмотр оплат водителей',
    'drivers_payments_confirm': 'Подтвердить оплату водителя',
    'driver_payment_moderation': 'Модерация оплат водителей',
    'cargo_categories_view': 'Просмотр категорий груза',
    'transport_types_view': 'Просмотр типов транспорта',
    'countries_view': 'Проосмотр стран',
    'cities_view': 'Просмотр городов',
    'system_users_view': 'Просмотр пользователей системы',
    'system_roles_view': 'Просмотр ролей системы',
    'calculator_view': 'Калькулятор стоимости',
    'contacts_view': 'Просмотр контакты',
    'order_cancel_messages_view': 'Просмотр причин отказов заказа',
    'metrics_view': 'Просмотр метрик',
    'news': 'Новости',
    'client-contract': 'Журнал договоров с Контрагентами',
    'feedback_request': 'Заявки на консультацию',
    'email_sender': 'Email‑рассылки',
    'reports': 'Отчеты'
}
